import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import './App.css';
import Fullpage from './components/FullPage';
import Contact from './pages/Contact';
import Impressum from './pages/Impressum';


const App = () => {
  
  return (
    <Router>
    <MainNavigation />
    <main>
    <Switch>

<Route path="/contact" exact>
<Contact />
</Route>
<Route path="/impressum" exact>
<Impressum />
</Route>
<Route path="/" exact>
<Fullpage />
</Route>
<Redirect to="/"/>
    </Switch>
      </main>

    </Router>
  );
}

export default App;
