import React from 'react';

import './Vehicles.css';
import image0 from '../shared/assets/Bild2.png';
import fuhrpark1 from '../shared/assets/fahr0.svg'
import fuhrpark2 from '../shared/assets/fuhr1.svg'
import fuhrpark3 from '../shared/assets/fuhr2.svg'
import fuhrpark4 from '../shared/assets/fuhr3.svg'
import ScrollAnimation from 'react-animate-on-scroll';

const Vehicles = () => {
    const sectionStyle = {
        backgroundImage: `url(${image0})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPositionY:'bottom',

      }

    return(
        <div id="Vehicles" style={sectionStyle} className="section">
        <div>
        <ScrollAnimation animateIn="fadeIn">
            <h1>Spedition mit eigenem Fuhrpark</h1> </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn"> <p>Für Ihre Speditions- und Transportaufgaben 
            steht Ihnen unser leistungsfähiger Fuhrpark zur Verfügung.
            Dafür bieten wir Ihnen alle erforderlichen Komponenten, in einem auf Ihre
            Anforderungen zugeschnittenen Katalog.<br /> <br />
            Dabei transportieren wir Ihre Fracht innerhalb Deutschlands, Europas und
            wenn gewünscht weltweit.</p></ScrollAnimation>
           
        </div>
        <div id="vehicles-box">
            <ScrollAnimation  animateIn="fadeInRight" animateOut="fadeOutRight"><img src={fuhrpark1} height={65} alt="vehicle" /></ScrollAnimation>
            <ScrollAnimation delay={100} animateIn="fadeInRight" animateOut="fadeOutRight"><img src={fuhrpark2} height={65} alt="vehicle" /></ScrollAnimation>
            <ScrollAnimation delay={200} animateIn="fadeInRight" animateOut="fadeOutRight"><img src={fuhrpark3} height={65} alt="vehicle" /></ScrollAnimation>
            <ScrollAnimation delay={300} animateIn="fadeInRight" animateOut="fadeOutRight"><img src={fuhrpark4} height={65} alt="vehicle" /></ScrollAnimation>
        </div>
        </div>
    )
}

export default Vehicles;