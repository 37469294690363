import React from 'react';
import './About.css';
import Partner1 from '../shared/assets/partner1.svg'
import Partner2 from '../shared/assets/partner2.svg'
import Partner3 from '../shared/assets/partner3.svg'
import ScrollAnimation from 'react-animate-on-scroll';

const About = () => {

    return(
        <div id="About" className="section">
            <div>
                <div>
                <div>
                <h2>Wir sind für Sie da!</h2>
                <p className="reverse">Die Individualität bei jeglichen Dienstleistungen ist die Basis unseres Handelns.
                Die Orientierung am Bedarf unserer Kunden hat höchste Priorität.
                Nutzen Sie daher unsere langjährige Erfahrung und unser Know-How.
                Überzeugen Sie sich von der ausgezeichneten Qualität und profitieren Sie von unserer persönlichen Einsatzbereitschaft.
                </p>
                </div>
                <div className="about-grid">
<div> <ScrollAnimation animateIn="fadeIn"> <h5>8</h5><p className="reverse">Partner</p></ScrollAnimation></div> 
<div><ScrollAnimation animateIn="fadeIn"><h5>1</h5><p className="reverse">Team</p></ScrollAnimation></div>  
<div><ScrollAnimation animateIn="fadeIn"><h5>15</h5><p className="reverse">Fahrzeuge</p></ScrollAnimation></div>
<div><ScrollAnimation animateIn="fadeIn"><h5>10</h5><p className="reverse">Mitarbeiter</p></ScrollAnimation></div>
                </div>
            </div>
            <div className="partner-imgs">
            <div><ScrollAnimation  animateIn="fadeInLeft"><img src={Partner1} className="partner-img" alt="partner"/></ScrollAnimation></div>
            <div><ScrollAnimation delay={100} animateIn="fadeInLeft"><img src={Partner2} className="partner-img" alt="partner"/></ScrollAnimation></div>
            <div><ScrollAnimation delay={200} animateIn="fadeInLeft"><img src={Partner3} className="partner-img" alt="partner"/></ScrollAnimation></div>
                
            </div>

            </div>
        </div>
    )
}

export default About;