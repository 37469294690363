import React from 'react';

import ScrollAnimation from 'react-animate-on-scroll';
import image0 from '../shared/assets/Bild1.png';
import image1 from '../shared/assets/swoosh.png';
import './Home.css';
import logo from '../shared/assets/logo_full_light.svg';


const Home = () => {
    let windowSize = window.innerWidth;
    let sectionInnerStyle;
    if(windowSize < 400) {
        sectionInnerStyle = {
            height:"100%",
            position: 'relative',
            margin:"auto 0 0",
    
    
        }
    }else{
            sectionInnerStyle = {
                height:"100%",
                position: 'relative',
                margin:"auto 0 0",
                backgroundPosition:'bottom',
                backgroundImage: `url(${image1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
    }
}
    const sectionStyle = {
        backgroundImage: `url(${image0})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPositionY:'bottom',

        backgroundColor: "#366680",
        height:"100vh",
        width:"100%"
        
    }




    const logoStyle = {
        margin: "0 0 2rem"
    }
    return(
    
<div id="Home" style={sectionStyle} className="section">
    <div style={sectionInnerStyle}>

       <div className="home-text">
       <img style={logoStyle} src={logo} alt="logo" />

       <ScrollAnimation animateIn="fadeIn">
            <h1>Wir sind Ihr zuverlässiger Partner für Transport</h1></ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">  <p>Branchenspezifische Anforderungen erforden individuelle Lösungen in der Zulieferung. 
           Wir sind ein junges und dynamisches Unternehmen. 
           Unsere Erfahrung macht uns zu einem kompetenten und zuverlässigen Partner für Transport.</p></ScrollAnimation>

       </div>
    </div>
</div>


)
}

export default Home;