import React from 'react';

import Home from '../pages/Home';
import Portfolio from '../pages/Portfolio';
import About from '../pages/About';
import Vehicles from '../pages/Vehicles';


const Fullpage = () => {
  return(
    <div>
    <Home />
    <Portfolio />
    <About />
    <Vehicles />
    </div>
  
  )

  };

export default Fullpage;