import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './Portfolio.css';

const Portfolio = () => {

    return(
    <div id="Portfolio" className="section">
    <div>
        <h1 className="reverse">Unsere Dienstleistung im Überblick</h1>
        <div className="work-grid">
            <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut"><div><p>Abholung und Lieferung</p></div></ScrollAnimation>
            <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut"><div><p>Flexibilität</p></div></ScrollAnimation>
            <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut"><div><p>Anmietung und Vermietung</p></div></ScrollAnimation>
            <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut"><div><p>Eigener Fuhrpark</p></div></ScrollAnimation>
        </div>
    </div>
    </div>
)
};

export default Portfolio;