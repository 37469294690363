import React from "react";
import './Contact.css';
import logo from '../shared/assets/logo_full.svg';
import Lottie from 'react-lottie';
import animationData from '../shared/assets/anim-data/lupe.json';

const Contact = () => {
    

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return(
    <div id="contact">
    <div className="contact-left">
        <div><img src={logo} alt="logo" /></div>
        <br />
        <div>
       <h2> Geschäftsführerin: Anna Hein</h2>
       <br />
       <p className="reverse">
        Tel.:<a href="tel:+49068346097000"> +49 06834 609700 0 </a><br/>
        Fax: +49 6834 609700 7 <br/>
        Email:<a href ="mailto:kadistransport@gmail.com"> kadistransport@gmail.com</a> <br/>
       </p>
       <br />
       <p className="reverse">
        Hauptstraße 28 <br />
        66773 Schwalbach<br /><br />
       </p>
              </div>
    </div>
    <div className="contact-right">
    <div>
        <Lottie height={95} width={95} options={defaultOptions} isClickToPauseDisabled={true} />
        </div>   
    <div>
    <h2>Job gesucht?</h2>
        <h3>Bewerben Sie sich noch heute.</h3>
        <p>
        <a href="mailto:kadistransport@gmail.com">Ganz einfach per Email</a>
        </p>
    </div>
 
    </div>  
    </div>
    )
}

export default Contact;